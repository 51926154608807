@font-face {
  font-family: "Gordita";
  font-style: normal;
  font-weight: 400;
  src: url("/public/fonts/Gordita-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Gordita";
  font-style: bold;
  font-weight: 700;
  src: url("/public/fonts/Gordita-Medium.woff2") format("woff2");
}